body.stage-rooms,
body.stage-traveller {
  @apply md:pb-0 pb-[5.625rem];
}

body.stage-payment {
  @apply md:pb-0 pb-[3.75rem];
}

body.stage-finished,
body.stage-payment-processing {
  @apply md:pb-0 pb-0;
}

body.stage-transaction-failed {
  @apply md:pb-0 pb-[6.25rem];
}

body.stage-404,
body.stage-500 {
  @apply pb-0;
}
