:root {
  --toastify-color-error: white;
}

@import './intercom.css';
@import './layout.css';
@import './fairing-survey.css';

.custom-radio::after {
  content: '';
  width: 0.35rem;
  height: 0.35rem;
  background-color: #fff;
  position: absolute;
  border-radius: 100%;
  margin: auto;
  left: 0;
  right: 0;
  top: 35%;
}
/* ----------------Mobile Modal Animation-------------- */
.modal-bottom-enter {
  opacity: 0;
}
.modal-bottom-enter.modal-bottom-enter-active {
  opacity: 0;
}
.modal-bottom-enter-done {
  opacity: 1;
  transition: 300ms linear;
}
.modal-bottom-exit {
  opacity: 1;
}
.modal-bottom-exit.modal-bottom-exit-active {
  opacity: 0;
  transition: 300ms linear;
}
.modal-bottom-exit-done {
  opacity: 0;
}

@media screen and (max-width: 640px) {
  .modal-bottom-enter {
    transform: translateY(100vh);
  }
  .modal-bottom-enter.modal-bottom-enter-active {
    transform: translateY(100vh);
  }
  .modal-bottom-enter-done {
    transform: translateY(0);
    transition: 300ms linear;
  }
  .modal-bottom-exit {
    transform: translateY(0);
  }
  .modal-bottom-exit.modal-bottom-exit-active {
    transform: translateY(100vh);
    transition: 300ms linear;
  }
  .modal-bottom-exit-done {
    transform: translateY(100vh);
  }

  .modal-top-enter {
    transform: translateY(-100vh);
  }
  .modal-top-enter.modal-top-enter-active {
    transform: translateY(-100vh);
  }
  .modal-top-enter-done {
    transform: translateY(0);
    transition: 300ms linear;
  }
  .modal-top-exit {
    transform: translateY(0);
  }
  .modal-top-exit.modal-top-exit-active {
    transform: translateY(-100vh);
    transition: 300ms linear;
  }
  .modal-top-exit-done {
    transform: translateY(-100vh);
  }
  .ReactModal__Content {
    position: unset !important;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
/* ----------------Stage Indicator Animation-------------- */
@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.progress {
  animation: progressBar 3s ease-in-out;
}
/*------------------------Trip info-------------------------------*/
.trio-info-gradiant {
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    rgba(23, 24, 26, 0.5) 50.52%,
    #17181a 100%
  );
}
.trip-info-list li::before {
  content: '\2022';
  font-size: 1.2rem !important;
  color: #c4c4c4;
  font-weight: bolder;
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 10px;
}

/*-----------------Scroll Bar----------------*/
.hide-webkit-scrollbar::-webkit-scrollbar {
  display: none;
}

/*--------------------------Loader-------------------------------*/
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
  color: #575b63;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

.small-loader {
  color: #737882 !important;
  display: inline-block;
  font-size: 4px !important;
  top: -8px;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.hide-webkit-scrollbar::-webkit-scrollbar {
  display: none;
}

.webkit-custom-scrollbar::-webkit-scrollbar {
  height: 0.2rem;
}
.webkit-custom-scrollbar::-webkit-scrollbar-track {
}
.webkit-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 1rem;
}
.webkit-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.tealshadow {
  box-shadow: 0px 5px 10px rgba(0, 195, 238, 0.15);
}

.modal-content {
  overflow-y: auto;
  max-height: calc(100% - 1.25rem);
}
.unscrollable .modal-content {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.unscrollable .modal-content-inner {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.unscrollable .modal-content-children-wrapper {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

fieldset {
  border: 0px !important;
}
.MuiFormControl-root {
  width: 100%;
}

/* -----------ToolTip--------------- */
.tippy-box[data-theme~='light-border'] {
  min-width: 360px;
  border: 1px solid rgb(0, 131, 160) !important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%) !important;
  padding: 0.625rem;
}
.tippy-box[data-theme~='light-border'][data-placement^='top']
  > .tippy-arrow:after {
  border-top-color: rgb(0, 131, 160) !important;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom']
  > .tippy-arrow:after {
  border-bottom-color: rgb(0, 131, 160) !important;
}
@media only screen and (max-width: 640px) {
  .tippy-box[data-theme~='light-border'] {
    padding: 0.625rem 1rem 0.75rem 0.625rem;
  }
}

/* -- -- -- -- -Toastify-- -- -- -- - */

.Toastify__toast-container {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding: 20px !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 0 !important;
  right: 0 !important;
}

.Toastify__toast {
  min-height: unset !important;
  margin-bottom: 1.25rem !important;
  background: white !important;
  color: unset !important;
  border-radius: 0.375rem !important;

  box-shadow: 0px 0px 10px 0px rgba(23, 24, 26, 0.1);
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast--error {
  border: 1px solid #fe5754;
}

.Toastify__toast--warning {
  border: 1px solid #ffc649;
}

.Toastify__toast--success {
  border: 1px solid #1ae68c;
}

.Toastify__toast--info {
  border: 1px solid #0083a0;
}

.Toastify__toast--default {
  border: 1px solid #17181a;
}

.inner-html a {
  text-decoration: underline;
}

.dealsaway-button {
  background: linear-gradient(92.61deg, #fb2f54 13.66%, #fc920e 221.62%);
}
.dealsaway-button:hover {
  background: linear-gradient(-87.39deg, #fb2f54 13.66%, #fc920e 221.62%);
}

.MuiPickersLayout-root {
  @apply !bg-[#FCFCFC];
}

.disclaimer-text a {
  @apply text-teal-800;
}