.inner-html-content {
  font-weight: 600;
  font-size: 0.875rem;
  color: #575b63;
  line-height: 1.25rem;
}

.inner-html-content a {
  color: #0083a0;
  text-decoration: underline;
}

.inner-html-content-da {
  font-weight: 600;
  font-size: 0.875rem;
  color: #575b63;
  line-height: 1.25rem;
}

.inner-html-content-da a {
  color: #fb2f54;
  text-decoration: underline;
}
