@import './font.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './typography.css';
@import './timer.css';
@import './modal.css';
@import 'toastify.css';
@import './inner-html-content.css';
