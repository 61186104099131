.typography-headline {
  @apply font-extrabold text-4xl text-dark-900;
}

.typography-title {
  @apply font-bold text-3xl text-dark-900;
}

.typography-sub-title {
  @apply font-semibold text-2xl text-dark-900;
}

.typography-number {
  @apply font-semibold text-xl text-dark-900;
}

.typography-text-bold {
  @apply font-semibold text-lg text-dark-900;
}

.typography-text {
  @apply font-semibold text-base text-dark-900;
}

.typography-label {
  @apply font-semibold text-sm text-dark-900;
}

.typography-small-text {
  @apply font-semibold text-xs text-dark-900;
}
