.fairing-survey__title {
  @apply font-semibold text-lg leading-5 text-dark-800 mb-5;
}

.fairing__responses,
.fairing__autosuggest__list,
.fairing__date-response {
  @apply my-5;
}

.fairing__response {
  @apply text-base font-medium text-dark-800 my-2 flex items-center gap-1.5 cursor-pointer w-fit;
}

.fairing__response-label,
.fairing__checkbox-control,
select.fairing__date-response__input {
  @apply cursor-pointer;
}

.fairing__checkbox-control {
  @apply hidden m-0 w-5 h-5 rounded-md bg-light-600 border border-light-900 outline-none appearance-none;
  display: grid;
  place-content: center;
}

.fairing__checkbox-control:checked {
  @apply bg-teal-900;
}

.fairing__checkbox-control::after {
  content: '✓';
  @apply text-sm opacity-0 transition-opacity text-light-600 mt-0.5 ml-0.5;
}

.fairing__checkbox-control:checked::after {
  @apply opacity-100;
}

.fairing__radio-control {
  @apply hidden m-0 w-5 h-5 rounded-full bg-light-600 border border-light-900 outline-none appearance-none;
  display: grid;
  place-content: center;
}

.fairing__radio-control.checked {
  @apply bg-teal-900;
}

.fairing__action.fairing__action--submit {
  @apply py-4 px-8 rounded-md font-semibold text-lg text-light-600 bg-teal-800 min-w-[13.125rem] flex justify-center items-center;
}

.fairing__branding {
  @apply hidden;
}

.fairing__other-response-wrapper {
  @apply flex gap-2 items-center;
}

.fairing__other-response__label {
  @apply hidden;
}

.fairing__other-response,
.fairing__date-response__input,
.fairing__autosuggest__input,
.fairing__autosuggest__option,
.fairing__nps-control,
.fairing__open-ended-response {
  @apply border border-light-900 focus-within:border-teal-900 focus-within:shadow-primary-900 transition-all bg-light-600 outline-none rounded-md py-2 px-4;
}

.fairing__autosuggest__option {
  @apply w-fit my-1 cursor-pointer;
}

.fairing__nps-controls {
  @apply w-fit max-w-full mx-auto flex !overflow-x-auto;
}

.fairing__nps-control {
  @apply cursor-pointer my-1 mx-0.5;
}

.fairing__nps-control[aria-checked='true'] {
  @apply bg-teal-900 text-light-600;
}

.fairing__nps-labels {
  @apply flex justify-between;
}
