.stage-rooms {
  --intercom-offset: 5rem;
}

.stage-traveller {
  --intercom-offset: 5rem;
}

.stage-payment {
  --intercom-offset: 3.3rem;
}

.stage-payment-processing,
.stage-finished {
  --intercom-offset: 0rem;
}

.stage-transaction-failed {
  --intercom-offset: 5.7rem;
}

/* to move intercom icon up in product-page (mobile mode) so that it doesn't block our components */
@media (max-width: 1024px) {
  body .intercom-launcher {
    margin-bottom: var(--intercom-offset) !important;
    transition: margin 0.3s ease-in-out;
  }

  body .intercom-lightweight-app-launcher {
    margin-bottom: var(--intercom-offset) !important;
    transition: margin 0.3s ease-in-out;
  }

  body .intercom-launcher-frame {
    margin-bottom: var(--intercom-offset) !important;
    transition: margin 0.3s ease-in-out;
  }
}
