:root {
    --toastify-toast-width: 400px !important;
    --toastify-toast-height: 70px !important;
}

.Toastify__toast {
    border-radius: 6px !important;
}

.Toastify__toast, .Toastify__toast-body, .Toastify__toast-container {
    padding: 0 !important;
}

.Toastify__toast {
    margin-bottom: 8px !important;
    border-radius: 6px !important;
}


.Toastify__toast:last-child {
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 480px) {
    :root {
        --toastify-toast-width: 400px !important;
        --toastify-toast-height: 72px !important;
    }

    .Toastify__toast-container {
        padding: 8px !important;
    }
}


.Toastify__close-button {
    display: none !important;
}