.embla {
  overflow: hidden;
  --slide-spacing: 0.625rem;
  --slide-size: 5.5rem;
}

.emblaContainer {
  display: flex;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}
@media (min-width: 768px) {
  .emblaContainer {
    margin-left: 1.9rem;
    margin-right: 1.9rem;
  }
}

.emblaSlide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  margin-left: var(--slide-spacing);
}

.emblaProgressBar {
  position: absolute;
  bottom: 0;
  left: 1.25rem;
  right: 1.25rem;
  height: 0.2rem;
  background-color: #dbdcdf;
  border-radius: 10px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .emblaProgressBar {
    left: 2.5rem;
    right: 2.5rem;
  }
}

.emblaProgressBarFilled {
  position: relative;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #0083a0;
}
