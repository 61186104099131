.timer-repeating-gradient-red {
  background: repeating-linear-gradient(
    -45deg,
    #fff0f0,
    #fff0f0 4px,
    #ffcac9 4px,
    #ffcac9 8px
  );
}

.timer-repeating-gradient-teal {
  background: repeating-linear-gradient(
    -45deg,
    #effcff,
    #effcff 4px,
    #0083a033 4px,
    #0083a033 8px
  );
}
